
<template>
<div>
  <div v-if="!isLoading" class="row account-page g-3 justify-content-center">
    <div class="col-md-10">
      <div class="card bg-primary bg-opacity-10"
       :style="`background-image:url(${absoluteUrl('/images/covers/profile-cover-2.jpg')})`">
        <div class="card-body">
          <div class="text-center">
            <div class="
                profile-user
                position-relative
                d-inline-block
                mx-auto
                mb-4">
              <img
                :src="absoluteUrl(shownUser.image)"
                class="
                  rounded-circle
                  avatar-xl
                  img-thumbnail
                  user-profile-image" alt="user-profile-image" />
            </div>
            <h5 class="fs-16 text-white mb-1">
              {{shownUser.first_name}} {{shownUser.last_name}}
            </h5>
            <p class="mb-0 text-white">{{shownUser.role}} / Joined on {{$filters.date(shownUser.created_at)}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-10">
      <div class="row g-3">
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-profile />
            </div>
          </div>
        </div>
          <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-information />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-password />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isLoading" class="row">
    <div class="col-12">
      <is-loading />
    </div>
  </div>
</div>
</template>

<script>

import IsLoading from '@/components/IsLoading.vue'
import UserEditProfile  from "./UserEditProfile.vue"
import UserEditInformation from "./UserEditInformation.vue"
import UserEditPassword from "./UserEditPassword.vue"

export default {
  name: "user-edit",
  components: {
    UserEditProfile,
    UserEditInformation,
    UserEditPassword,
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    shownUser(){
      return this.$store.state.userList.user
    },
  },
   methods:{
    fetchUser(){
      this.isLoading = true
      this.$store.dispatch("userList/fetchUser", this.$route.params.userId)
      .then((response) => {
        if(response.data.success){
          this.isLoading = false
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    }
  },
  created() {
    this.$store.dispatch("userList/fetchRoles")
    this.fetchUser();
  }
}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
</style>
